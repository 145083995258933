import styled from "styled-components";

export const Gradient = styled("div")`
  background: linear-gradient(-180deg, #dae0dc 0%, #e6e8dd 67%);
  position: relative;
`;

export const FeatureHeading = styled("div")<{ extraSpacing?: boolean }>`
  color: #637e93;
  font-weight: 300;
  font-size: 2.0rem;
  margin-bottom: 12px;
  line-height: 2.125rem;
  ${p =>
    p.extraSpacing
      ? `
    padding-top: 160px;
  `
      : "padding-top: 50px;"}
  @media only screen and (max-width: 960px) {
    padding-top: 0;
  }
`;

export const Block = styled("div")<{ reverse?: boolean }>`
  margin-top: 80px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 60px;
  @media only screen and (max-width: 960px) {
    flex-direction: ${p => (p.reverse ? "column-reverse" : "column")};
    align-items: center;
  }
  @media only screen and (max-width: 900px) {
    padding-top: 0;
    padding-bottom: 20px;
  }
`;

export const Statement = styled("div")`
  font-size: 1.125rem;
  margin-bottom: 0.5em;
  color: #666;
`;

export const TextSide = styled("div")`
  margin-top: 15px;
  max-width: 330px;
`;
