import * as React from "react";
import { Wrapper } from "../LandingPage/components";
import { LandingPageButton } from "../LandingPage/Hero/components";
import { BigBloggerText, VegHeroBackground } from "./components";
import { FlexCenter } from "../../ui/Flex";

interface Props {}

export const Outro: React.FC<Props> = () => {
  return (
    <VegHeroBackground style={{ marginTop: 0 }} outro>
      <Wrapper style={{ paddingBottom: "10em" }}>
        <BigBloggerText>Get Started with your digital cookbook!</BigBloggerText>
        <FlexCenter>
          <LandingPageButton
            onClick={() => {
              window.location.href =
                "https://calendly.com/julie-saffron/saffron-demo";
            }}
            style={{ marginTop: 50 }}
            color="red"
          >
            Schedule a demo
          </LandingPageButton>
        </FlexCenter>
      </Wrapper>
    </VegHeroBackground>
  );
};
