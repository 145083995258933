import * as React from "react";
import { TinyWrapper } from "../LandingPage/components";
import {
  Block,
  FeatureHeading,
  Statement,
  TextSide,
} from "../LandingPage/Features/components";
import editPhone from "./edit-phone.png";
import scalePhone from "./scale-phone.png";
import { BigBloggerText } from "./components";

interface Props {}

export const Benefits: React.FC<Props> = () => {
  return (
    <div
      style={{
        background: "linear-gradient(180.1deg, #DFDACA 27.17%, #FAFAFA 74.01%)",
      }}
    >
      <TinyWrapper style={{ paddingTop: 30 }}>
        <BigBloggerText style={{ paddingTop: 0, paddingBottom: 30 }}>
          Why Saffron
        </BigBloggerText>
        <Block>
          <div style={{ height: 465 }}>
            <img
              src={editPhone}
              style={{
                maxWidth: 250,
                width: "100%",
                height: "auto",
              }}
              alt="edit recipe on app"
            />
          </div>
          <TextSide>
            <FeatureHeading>For bloggers</FeatureHeading>
            <Statement>
              Easily create any number of cookbooks from your recipe collection.
            </Statement>
            <Statement>
              Saffron takes care of the design, formatting, and screen
              optimization for you!
            </Statement>
            <Statement>
              Edit your cookbook anytime and everyone gets the change
              immediately.
            </Statement>
            <Statement>
              Handles the whole cookbook creation process, no need to use
              multiple programs.
            </Statement>
          </TextSide>
        </Block>
      </TinyWrapper>
      <TinyWrapper>
        <Block reverse>
          <TextSide>
            <FeatureHeading>For your followers</FeatureHeading>
            <Statement>
              Using a Saffron digital cookbook is a much better experience than
              an ebook.
            </Statement>
            <Statement>
              Recipes are easy to cook from whether your using our website or
              iOS/Android app.
            </Statement>
            <Statement>Automatically scale ingredients.</Statement>
            <Statement>Generate a grocery list from recipes.</Statement>
            <Statement>Search cookbook by recipe name or ingredient.</Statement>
          </TextSide>
          <div style={{ height: 465 }}>
            <img
              src={scalePhone}
              style={{
                maxWidth: 250,
                width: "100%",
                height: "auto",
              }}
              alt="scale recipe on app"
            />
          </div>
        </Block>
      </TinyWrapper>
    </div>
  );
};
