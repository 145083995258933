import heroBackground from "./hero-background.jpg";
import mobileHero from "./hero-mobile.jpg";
import mobileOutro from "./outro-background.jpg";
import { HEADER_HEIGHT } from "../LandingPage/Hero/components";
import styled from "styled-components";
import { theme } from "../../ui/theme";

export const VegHeroBackground = styled("div")<{ outro?: boolean }>`
  margin-top: ${HEADER_HEIGHT}px;
  padding-top: ${HEADER_HEIGHT}px;
  background: url(${heroBackground});
  background-position-x: 70%;
  background-position-y: 20%;
  background-color: #f1efe8;
  background-repeat: no-repeat;
  display: flex;
  @media only screen and (max-width: 1254px) {
    background-position-x: 50%;
  }
  @media only screen and (max-width: 769px) {
    background-position-x: 60%;
  }
  @media only screen and (max-width: 425px) {
    padding-top: 0;
    background: url(${p => (p.outro ? mobileOutro : mobileHero)});
    background-position-y: 100%;
    background-color: #f1efe8;
    background-size: 100%;
    background-repeat: no-repeat;
  }
`;

export const BigBloggerText = styled.div`
  font-family: ${theme.fonts.secondary};
  color: #0d0d0d;
  font-size: 3.375rem;
  text-align: center;
  font-weight: 300;
  width: 100%;
  max-width: 600px;
  padding-top: 90px;
  line-height: 3.75rem;
  margin: auto;
  @media only screen and (max-width: 900px) {
    font-size: 2.875rem;
    line-height: 3.375rem;
  }
  @media only screen and (max-width: 425px) {
    padding-top: 30px;
    padding-bottom: 80px;
    max-width: 263px;
    text-align: center;
    font-size: 2.5rem;
    line-height: 3rem;
  }
`;
