import * as React from "react";
import styled from "styled-components";

interface Props {
  id: string;
}

const VideoContainer = styled("div")`
  width: 100%;
  max-width: 560px;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 2px 4px 0px;
  margin: auto;
  & iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    max-width: 560px;
    max-height: 315px;
    position: absolute;
  }

  @media only screen and (min-width: 640px) {
    padding-bottom: 315px;
  }
`;

export const YouTubeVideo: React.FC<Props> = ({ id }) => {
  return (
    <VideoContainer>
      {/*
    // @ts-ignore */}
      <iframe
        title={"youtube-" + id}
        width="560"
        height="315"
        src={`https://www.youtube.com/embed/${id}`}
        {...({ frameborder: "0" } as any)}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      />
    </VideoContainer>
  );
};
