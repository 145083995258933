import * as React from "react";
import { StickyHeader } from "../LandingPage/StickyHeader";
import { Hero } from "./Hero";
import { HowBlock } from "./HowBlock";
import { Benefits } from "./Benefits";
import { Outro } from "./Outro";
import { Footer } from "../LandingPage/Footer";
import { SEO } from "../../components/seo";

const BloggerLandingPage: React.FC<{}> = () => {
  return (
    <div style={{ width: "100%" }}>
      <SEO
        title="Saffron for Bloggers"
        description="Use Saffron to create digital cookbooks to share recipes with your followers."
      />
      <StickyHeader variant="blogger" />
      <Hero />
      <HowBlock />
      <Benefits />
      <Outro />
      <Footer />
    </div>
  );
};

export default BloggerLandingPage;
