import * as React from "react";
import styled from "styled-components";
import { UploadCloud, Share2, Camera, Layout } from "react-feather";
import { theme } from "../../ui/theme";
import { Wrapper } from "../LandingPage/components";
import { PrettyMyLink } from "../../ui/PrettyLink";
import { YouTubeVideo } from "../../ui/YouTubeVideo";

interface Props {}

const HeaderTitle = styled.div`
  font-family: ${theme.fonts.secondary};
  color: #0d0d0d;
  font-size: 2.5rem;
  text-align: center;
  font-weight: 300;
  width: 100%;
  margin-bottom: 15px;
`;

const SectionTitle = styled.div`
  font-family: ${theme.fonts.secondary};
  color: #0d0d0d;
  font-size: 1.6875rem;
  font-weight: 300;
  margin-left: 10px;
`;

interface SectionBlock {
  title: string;
  paragraph: string | JSX.Element;
  icon?: JSX.Element;
}

const data: SectionBlock[] = [
  {
    title: "Import Recipes",
    paragraph:
      "Saffron will bulk import recipes from your blog to help you quickly get started building your digital cookbook.",
    icon: <UploadCloud size={40} color="#70A3B7" />,
  },
  {
    title: "Organize Content",
    paragraph:
      "Give your cookbook a name, arrange with sections and then fill it with your recipes.",
    icon: <Layout size={40} color="#B56545" />,
  },
  {
    title: "Add Branding",
    paragraph: (
      <>
        Saffron will generate a cookbook cover and{" "}
        <PrettyMyLink to="/cookbook/spice-and-rice">landing page</PrettyMyLink>{" "}
        using your branding.
      </>
    ),
    icon: <Camera size={40} color="#972409" />,
  },
  {
    title: "Share Cookbooks",
    paragraph:
      "Generate a custom link to access your cookbook and use it for marketing, lead generation, and on your blog.",
    icon: <Share2 size={40} color="#977535" />,
  },
];

const Grid = styled.div`
  display: grid;
  width: 100%;
  margin: auto;
  max-width: 800px;
  grid-template-columns: repeat(auto-fill, minmax(325px, 1fr));
  justify-items: center;
  row-gap: 2em;
  @media only screen and (max-width: 450px) {
    grid-template-columns: 1fr;
  }
`;

export const HowBlock: React.FC<Props> = () => {
  return (
    <div
      style={{ backgroundColor: "#fafafa", paddingTop: 30, paddingBottom: 80 }}
    >
      <Wrapper>
        <HeaderTitle>How it works</HeaderTitle>
        <Grid>
          {data.map(x => (
            <div key={x.title} style={{ width: "100%", maxWidth: 325 }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 10,
                }}
              >
                {x.icon}
                <SectionTitle>{x.title}</SectionTitle>
              </div>
              <div>{x.paragraph}</div>
            </div>
          ))}
        </Grid>
        <div style={{ marginTop: 40 }}>
          <YouTubeVideo id="d65EzyMnDI0" />
        </div>
      </Wrapper>
    </div>
  );
};
