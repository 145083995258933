import * as React from "react";
import { LandingPageButton } from "../LandingPage/Hero/components";
import { Wrapper } from "../LandingPage/components";
import { BigBloggerText, VegHeroBackground } from "./components";
import { FlexCenter } from "../../ui/Flex";

interface Props {}

export const Hero: React.FC<Props> = () => {
  return (
    <VegHeroBackground>
      <Wrapper style={{ paddingBottom: "10em" }}>
        <BigBloggerText>
          Make a digital cookbook in less than 30 minutes!
        </BigBloggerText>
        <FlexCenter>
          <LandingPageButton
            onClick={() => {
              window.location.href = "mailto:julie@mysaffronapp.com";
            }}
            style={{ marginTop: 50 }}
            color="black"
          >
            Let’s talk!
          </LandingPageButton>
        </FlexCenter>
      </Wrapper>
    </VegHeroBackground>
  );
};
